<template>
    <content-view>
        <vue-progress-bar />
        <div class='lesson-add' v-if='isLoaded'>
            <course-header
                :is-sending='isSending'
                :return-route='backRouteLink'
                :btn-handler='onSubmit'
                btn-title='Сохранить'
            />
            <div class='lesson-add__content'>
                <ValidationObserver tag='form' ref='observer'>
                    <ValidationProvider
                        tag='div'
                        class='lesson-add__comments'
                        vid='status'
                        v-slot='{ errors }'
                        :rules='`required`'
                    >
                        <label
                            for='id_status'
                            class='v-label lesson-add__comments-label'
                        >Комментарии</label
                        >
                        <v-select2
                            id='id_status'
                            :options='selectList'
                            label='name'
                            v-model='form.status_comment'
                            :reduce='status => status.id'
                            :clearable='false'
                            :searchable='false'
                        ></v-select2>
                        <span
                            class='v-error animated fadeInDown'
                            v-if='errors.length'
                        >
                            {{ errors[0] }}
                        </span>
                    </ValidationProvider>

                    <!--                    <ValidationProvider class="mt-30" tag="div" vid="name" v-slot="{errors}" :rules="`required`">
                        <input v-model="form.name" class="course-add__content-title v-input v-input&#45;&#45;noborder" type="text" placeholder="Название урока...">
                        <span class="v-error animated fadeInDown" v-if="errors.length">
                            {{errors[0]}}
                        </span>
                    </ValidationProvider>-->

                    <ValidationProvider
                        class='mt-30'
                        tag='div'
                        vid='text'
                        v-slot='{ errors }'
                        :rules='`required`'
                    >
                        <new-editor
                            v-model='form.text'
                            :data='form.text'
                            top-placeholder='Название урока'
                            bottom-placeholder='Описание урока'
                            id='id_text'
                            no-vidget
                        />
                        <span
                            class='v-error animated fadeInDown'
                            id='error_text'
                            v-if='errors.length'
                        >
                            {{ errors[0] }}
                        </span>
                    </ValidationProvider>
                </ValidationObserver>
            </div>
        </div>
        <loading-spinner v-else />
    </content-view>
</template>

<script>
    import session from '@/api/session'

    export default {
        name: 'LessonChange',
        components: {
            CourseHeader: () => import('@/components/course/CourseHeader'),
            ContentView: () => import('@/views/menu/ContentView'),
            NewEditor: () => import('@/components/editor/newEditor'),
            LoadingSpinner: () => import('@/components/LoadingSpinner')
        },
        data() {
            return {
                form: {
                    status: null,
                    image: null,
                    text: '',
                    name: ''
                },
                selectList: [],
                moduleId: null,
                lessonId: null,
                courseId: this.$route.params.course_id,
                isLoaded: false,
                isSending: false
            }
        },
        computed: {
            backRouteLink() {
                return this.lessonId
                    ? `/courses/${this.courseId}/lesson/${this.lessonId}`
                    : `/courses/${this.courseId}`
            }
        },
        methods: {
            async beforeCreate() {
                try {
                    await session.get(
                        '/api/v1/course-lesson/access_change/'
                    )
                } catch (error) {
                    this.$router.push('/403')
                }
            },
            async onSubmit() {
                this.isSending = true
                this.$Progress.start()
                const isValid = await this.$refs.observer.validate()
                if (isValid) {
                    try {
                        const { form } = this
                        const data = {
                            ...form,
                            text: form.text.html
                        }

                        const content = form.text.json.content
                        const contentTitle = content.find(
                            item => item.type === 'title'
                        ).content
                        if (!content.length || !contentTitle) {
                            this.$refs.observer.setErrors({
                                text: ['Введите название урока']
                            })
                            this.$nextTick(() => {
                                this.$scrollTo('#error_text', { offset: 300 })
                            })
                            this.isSending = false
                            return false
                        } else {
                            data.name = contentTitle.map((variable) => variable.text).join('')
                        }
                        const { courseId } = this
                        if (this.lessonId) {
                            await session.put(
                                `/api/v1/course-lesson/${this.lessonId}/`,
                                {
                                    ...data,
                                    module: this.moduleId
                                }
                            )
                        } else {
                            await session.post(
                                '/api/v1/course-lesson/',
                                data
                            )
                        }
                        this.$router.push(`/courses/${courseId}`)
                    } catch (error) {
                        console.log(error)
                    }
                }
            }
        },
        async created() {
            const request = await session.get(
                '/api/v1/course-lesson/default_data/'
            )
            this.selectList = request.data.status
            this.form.status_comment = this.selectList[0].id

            this.moduleId = this.$route.params.module_id || null
            this.form.module = this.moduleId

            this.lessonId = this.$route.params.lesson_id || null
            if (this.lessonId) {
                const request = await session.get(
                    `/api/v1/course-lesson/${this.lessonId}/`
                )
                this.form = {
                    ...request.data,
                    status_comment:
                        request.data.comments_allowed === true ? 0 : 1
                }
            }
            this.isLoaded = true
        }
    }
</script>

<style lang='scss' scoped>
    @import '#sass/v-style';

    .lesson-add {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 1024px;
        margin: 0 auto;
        height: 100%;
        @include below(1000px) {
            margin: 0;
            padding: 0 25px;
        }

        &__content {
            margin-top: 50px;
        }

        &__comments {
            max-width: 250px;
        }
    }
</style>
